@import "fonts";
@import "toggle-area";
@import "print";
@import "table-totals";

body,
html {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: $font-family-sans-serif;
    font-weight: 300;
}

* {
    box-sizing: border-box;
}

button {
    font-family: inherit;
}

a {
    color: inherit;
}

b,
strong {
    font-weight: 500;
}

input {
    font-family: $font-family-sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

img {
    max-width: 100%;
}

.mat-checkbox-layout {
    white-space: normal !important;
    display: block !important;
    width: 100%;
}

.mat-drawer-container {
    background-color: #fff;
    z-index: unset !important;
}

// Solved using javascript instead
//.mat-drawer {
//    // Make sure it is in front of tawk.to chat.
//    // This is not an exaggeration. The tawk.to z index is really high.
//    z-index: 10000000000 !important;
//}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: currentColor;
}

.cdk-mouse-focused,
.cdk-program-focused {
    outline: 0;
}

.mat-menu-panel {
    max-height: 400px !important;
}

.mat-menu-item {
    line-height: 40px !important;
    height: 40px !important;
}

.mat-tooltip {
    background-color: #fff;
    color: #000 !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0 !important;
    font-size: 15px;
    padding: 3px 10px;
}

.mat-radio-button {
    display: block !important;
}

.mat-form-field {
    width: 100%;
}

.mat-snack-bar-container {
    border-radius: 2px !important;
    color: #fff;
    padding: 18px 24px !important;
}

.mat-simple-snackbar-action button {
    color: #fff;
    text-transform: uppercase;
}

.nc-snack-bar-error {
    background-color: #d50048;
}

.slide-show-block {
    overflow: hidden;
}

.button-container {
    text-align: right;
}

.button-container button {
    margin: 5px 0 5px 5px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    padding-top: .33em !important; // Dense theme
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding-bottom: 0.33em !important; // Dense theme
}

.button-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -7px 0 -8px;
    white-space: nowrap;

    > * {
        margin: 15px 7px 0 8px;
    }
}

fieldset {
    border: 1px solid #e5e5e5;
    padding: 15px 10px;
    margin-bottom: 15px;

    > label {
        display: block;
        margin-top: -25px;
    }
}

.card {
    // A box for admin content
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    max-width: 1400px;
}

.image-shadow {
    box-shadow: 1px 2px 7px rgba(#000, 0.14);
    margin-bottom: 8px;
    border-radius: 2px;
    overflow: hidden;
}

// <mat-hint> element does not work with long texts.
.mat-hint {
    padding: 0 1em;
    color: rgba(0, 0, 0, 0.6);
    font-size: 75%;
    margin-top: -15px;
    margin-bottom: 15px;
}

.warning {
    &-color {
        color: $warning;
    }
}

.error {
    &-color {
        color: $danger;
    }
}

.gap-1 { gap: 0.25rem!important; }
.gap-2 { gap: 0.5rem!important; }
.gap-3 { gap: 1rem!important; }
.gap-4 { gap: 1.5rem!important; }
.gap-5 { gap: 3rem!important; }

.flex-children-even {
    > * {
        flex: 1;
    }
}
