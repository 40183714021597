
.expand-toggle-button {
    cursor: pointer;
    display: inline-block;
    padding-right: 25px;
    position: relative;
    color: #666;
    span {
        text-transform: uppercase;
        font-size: 16px;
        display: inline-block;
        min-width: 55px;
        text-align: center;
        @media (max-width: 400px) {
            display: none;
        }
    }
    &:after {
        content: '';
        display: block;
        height: 15px;
        width: 15px;
        transform: rotate(45deg);
        border: 2px solid currentColor;
        border-left: 0;
        border-top: 0;
        position: absolute;
        top: -1px;
        right: 3px;
    }
    &.open:after{
        transform: rotate(-135deg);
        top: 9px;
    }
}

.toggle-area {
    height: 0;
    overflow: hidden;
    position: relative;
    top: -10px;
    opacity: 0;
    transition: top 0.5s, opacity 0.5s;
    &.open {
        height: auto;
        top: 0;
        opacity: 1;
    }
}
