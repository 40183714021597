@media print {
    mat-sidenav-container {
        position: relative !important;
    }

    .print-hide,
    nc-site-header,
    nc-desktop-shop-menu,
    nc-breadcrumbs,
    nc-site-footer,
    *[nc-button],
    {
        display: none !important;
    }

}
