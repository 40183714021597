@import "../../styles/shared";

.totals {
    h2 {
        text-transform: uppercase;
    }
    table {
        width: 100%;
        border-spacing: 0;
        font-size: 16px;
        @media (min-width: 1350px) {
            font-size: 18px;
        }
        td {
            padding: 2px 0;
            vertical-align: bottom;
        }
        td + td {
            padding-left: 15px;
        }
        td:last-child {
            text-align: right;
            font-family: $font-family-serif;
        }
        tbody tr:first-child td {
            border-top: 1px solid #d6d6d6;
            padding-top: 10px;
        }
        tbody tr:last-child td {
            padding-bottom: 10px;
        }
        tfoot tr:first-child td {
            padding-top: 7px;
            border-top: 1px solid #d6d6d6;
        }
        tfoot tr.larger td {
            font-size: 18px;
            font-weight: 500;
            @media (min-width: 500px) {
                font-size: 23px;
            }
        }
        .totals-label i {
            color: #d8317f;
            margin-right: 10px;
        }
    }

    .totals-value {
        white-space: nowrap;
    }

    .totals-value.negative {
        color: $pink;
    }
}

