
//$font-path

@font-face {
    font-family: "MaryKayScript";
    src: url("/assets/fonts/MaryKayScript-Regular.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "MaryKayScript";
    src: url("/assets/fonts/MaryKayScript-Bold.otf");
    font-weight: 500;
    font-style: normal;
}

/*
@font-face {
    font-family: "Font Awesome 3";
    src: url("./assets/fonts/fontawesome-webfont.eot?v=3.2.1");
    src: url("./assets/fonts/fontawesome-webfont.eot?#iefix&v=3.2.1") format("embedded-opentype"),
        url("./assets/fonts/fontawesome-webfont.woff?v=3.2.1") format("woff"),
        url("./assets/fonts/fontawesome-webfont.ttf?v=3.2.1") format("truetype"),
        url("./assets/fonts/fontawesome-webfont.svg#fontawesomeregular?v=3.2.1") format("svg");
    font-weight: normal;
    font-style: normal;
}
*/
