/**
 * Bootstrap overrides
 */

$container-max-widths: (
    xl: 1350px,
);

/**
 * Font Awesome overrides
 */

$fa-font-path: "/assets/fontawesome-pro/webfonts";

/**
 * Bootstrap
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/**
 * Font Awesome
 */


@import "src/assets/fontawesome-pro/scss/variables";
@import "src/assets/fontawesome-pro/scss/mixins";

/**
 * Project variables
 */

$site-header-top-height: 75px;
$main-menu-height: 45px;
$shop-menu-height-desktop: 45px;
$shop-menu-height-mobile: 55px;
$header-height-mobile: $main-menu-height + $shop-menu-height-mobile;
$header-height-desktop: $site-header-top-height + $main-menu-height + $shop-menu-height-desktop;
$footer-height: 45px;

$font-family-sans-serif: 'Noto Sans', sans-serif;  // Previously: "MaryKaySans", "Open Sans", Arial, sans-serif;
$font-family-serif: 'Noto Serif', serif;  // Previously: "MaryKaySerif", Times, serif;
$font-family-script: "MaryKayScript", Times, serif;
// This font is only used in the header and should not be used anywhere else
$font-family-sans-serif-alt: "Helvetica Neue", Helvetica, Arial, sans-serif;

// Base pink
$brand-base-pink: #F8DADF;

// Tertiary Colors - Grays
$brand-gray-1: #DBD9D6;
$brand-gray-2: #8A8C8C;
$brand-gray-3: #555759;
$brand-gray-4: #111820;

// Tertiary Colors - Purples
$brand-purple-1: #C9BCD0;
$brand-purple-2: #A391B2;
$brand-purple-3: #7E6990;
$brand-purple-4: #644B78;
$brand-purple-5: #402B56;

// Secondary pinks
$brand-secondary-1: #F6CDD4;
$brand-secondary-2: #FBB5C3;
$brand-secondary-3: #FFA4B4;
$brand-secondary-4: #ED80A8;
$brand-secondary-5: #E74683;
$brand-secondary-6: #F3716D;
$brand-secondary-7: #D7006D;

// Other
$brand-green: #51b10b;

// Webshop pink
$brand-pink: $brand-secondary-5;
$pink: $brand-pink; // override bootstrap default pink with brand pink which is similar

/**
 * Mixins
 */

@mixin cut-corner($color, $size, $position) {
    background-size: $size+-1px $size+-1px, 100% 100%, 100% 100%;
    background-repeat: no-repeat;
    @if $position == left {
        background-image: linear-gradient(to bottom right, transparent 49%, $color 50%), linear-gradient($color, $color), linear-gradient($color, $color);
        background-position: 0% 0%, $size 0%, 100% $size;
    }
    @else {
        background-image: linear-gradient(to bottom left, transparent 49%, $color 50%), linear-gradient($color, $color), linear-gradient($color, $color);
        background-position: 100% 0%, -$size 0%, 100% $size;
    }
}

@mixin heading() {
    font-family: $font-family-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.2;
    margin: 0;
    @include media-breakpoint-down(lg) {
        font-size: 30px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 26px;
    }
}

@mixin product-name() {
    font-family: $font-family-serif;
    font-weight: 500;
    line-height: 1.2;
    color: #282828;
}
