/* You can add global styles to this file, and also import other style files */

/**
 * Variables, functions and mixins
 */

@import "./app/styles/shared";

/**
 * Bootstrap
 */

@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";

/**
 * Font Awesome
 */

@import "assets/fontawesome-pro/scss/core";
@import "assets/fontawesome-pro/scss/larger";
@import "assets/fontawesome-pro/scss/fixed-width";
@import "assets/fontawesome-pro/scss/list";
@import "assets/fontawesome-pro/scss/bordered-pulled";
@import "assets/fontawesome-pro/scss/animated";
@import "assets/fontawesome-pro/scss/rotated-flipped";
@import "assets/fontawesome-pro/scss/stacked";
@import "assets/fontawesome-pro/scss/icons";
@import "assets/fontawesome-pro/scss/screen-reader";
@import "assets/fontawesome-pro/scss/light";
@import "assets/fontawesome-pro/scss/brands";

/**
 * Angular material
 */

@import "~@angular/material/theming";
// Plus imports for other components in your app.

$typography: mat-typography-config(
    $font-family: $font-family-sans-serif
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pink: (
    50: #fce9f0,
    100: #f8c8da,
    200: #f3a3c1,
    300: #ee7ea8,
    400: #eb6296,
    500: #e74683,
    600: #e43f7b,
    700: #e03770,
    800: #dd2f66,
    900: #d72053,
    A100: #ffffff,
    A200: #ffdbe4,
    A400: #ffa8be,
    A700: #ff8fab,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$primary: mat-palette($pink);
$accent: mat-palette($pink);

// The warn palette is optional (defaults to red).
$red: (
    50: #fae0e9,
    100: #f2b3c8,
    200: #ea80a4,
    300: #e24d7f,
    400: #db2663,
    500: #d50048,
    600: #d00041,
    700: #ca0038,
    800: #c40030,
    900: #ba0021,
    A100: #ffe3e6,
    A200: #ffb0b9,
    A400: #ff7d8c,
    A700: #ff6375,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$warn: mat-palette($red);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);

/**
 * Project styles
 */

@import "./app/styles/global/global";
